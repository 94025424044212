import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../controller';
import { CalendarContext } from '../../../utils/context/contextFactory';
import {
  createInitializeWidgetAction,
  InitializeWidget,
} from './initializeWidget/initializeWidget';
import {
  createOnDateSelectedAction,
  OnDateSelected,
} from './onDateSelected/onDateSelected';
import {
  createGoToNextAvailableDateAction,
  GoToNextAvailableDate,
} from './goToNextAvailableDate/goToNextAvailableDate';
import { createSetSelectedMonthAction } from './setSelectedMonth/setSelectedMonth';
import {
  createOnTimeSelectedAction,
  OnTimeSelected,
} from './onTimeSelected/onTimeSelected';
import {
  createOnSlotOptionSelectedAction,
  OnSlotOptionSelected,
} from './onSlotOptionSelected/onSlotOptionSelected';
import {
  createOnTimezoneSelectedAction,
  OnTimezoneSelected,
} from './onTimezoneSelected/onTimezoneSelected';
import { createOnSubmitAction, OnSubmit } from './onSubmit/onSubmit';
import { createRefetchPlatformDataAction } from './refetchPlatformData/refetchPlatformData';
import { createSetCalendarErrorsAction } from './setCalendarErrors/setCalendarErrors';
import {
  createOnClearBookingDetailSelectedOptionsAction,
  OnClearBookingDetailSelectedOptions,
} from './onClearBookingDetailSelectedOptions/onClearBookingDetailSelectedOptions';
import { createSetSelectedTimeAction } from './setSelectedTime/setSelectedTime';
import {
  createNavigateToMembersAreaAction,
  NavigateToMembersArea,
} from './navigateToMembersArea/navigateToMembersArea';
import {
  createOnMonthSelectedAction,
  OnMonthSelected,
} from './onMonthSelected/onMonthSelected';
import {
  createOnDialogCloseAction,
  OnDialogClose,
} from './onDialogClose/onDialogClose';
import {
  createOnDialogConfirmAction,
  OnDialogConfirm,
} from './onDialogConfirm/onDialogConfirm';
import {
  createOnFilterChanged,
  OnFilterChanged,
} from './onFilterChanged/onFilterChanged';
import { createSetSelectedDateAction } from './setSelectedDate/setSelectedDate';
import {
  createOnTimePickerNotificationCtaClick,
  OnTimePickerNotificationCtaClick,
} from './onTimePickerNotificationCtaClick/onTimePickerNotificationCtaClick';
import {
  createOnToastCloseAction,
  OnToastClose,
} from './onToastClose/onToastClose';

export type CalendarActions = {
  initializeWidget: InitializeWidget;
  onDateSelected: OnDateSelected;
  goToNextAvailableDate: GoToNextAvailableDate;
  onMonthSelected: OnMonthSelected;
  onTimeSelected: OnTimeSelected;
  onSlotOptionSelected: OnSlotOptionSelected;
  onSubmit: OnSubmit;
  onClearBookingDetailSelectedOptions: OnClearBookingDetailSelectedOptions;
  onTimezoneSelected: OnTimezoneSelected;
  navigateToMembersArea: NavigateToMembersArea;
  onDialogClose: OnDialogClose;
  onDialogConfirm: OnDialogConfirm;
  onFilterChanged: OnFilterChanged;
  onTimePickerNotificationCtaClick: OnTimePickerNotificationCtaClick;
  onToastClose: OnToastClose;
};

export const createCalendarActions = (
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
): CalendarActions => {
  const setSelectedDate = createSetSelectedDateAction(actionFactoryParams);
  const setSelectedMonth = createSetSelectedMonthAction(actionFactoryParams);
  const refetchPlatformData = createRefetchPlatformDataAction(
    actionFactoryParams,
    setSelectedDate,
    setSelectedMonth,
  );
  const setSelectedTime = createSetSelectedTimeAction(actionFactoryParams);
  const setCalendarErrors = createSetCalendarErrorsAction(actionFactoryParams);
  const onDialogClose = createOnDialogCloseAction(actionFactoryParams);
  const goToNextAvailableDate = createGoToNextAvailableDateAction(
    actionFactoryParams,
    setSelectedDate,
  );

  return {
    initializeWidget: createInitializeWidgetAction(
      actionFactoryParams,
      setSelectedDate,
      setSelectedMonth,
    ),
    onDateSelected: createOnDateSelectedAction(
      actionFactoryParams,
      setSelectedDate,
    ),
    goToNextAvailableDate,
    onMonthSelected: createOnMonthSelectedAction(
      actionFactoryParams,
      setSelectedMonth,
    ),
    onTimeSelected: createOnTimeSelectedAction(
      actionFactoryParams,
      setSelectedTime,
    ),
    onSlotOptionSelected: createOnSlotOptionSelectedAction(
      actionFactoryParams,
      setCalendarErrors,
    ),
    onSubmit: createOnSubmitAction(actionFactoryParams, setCalendarErrors),
    onClearBookingDetailSelectedOptions: createOnClearBookingDetailSelectedOptionsAction(
      actionFactoryParams,
    ),
    onTimezoneSelected: createOnTimezoneSelectedAction(
      actionFactoryParams,
      refetchPlatformData,
      setSelectedTime,
    ),
    navigateToMembersArea: createNavigateToMembersAreaAction(
      actionFactoryParams,
    ),
    onFilterChanged: createOnFilterChanged(
      actionFactoryParams,
      refetchPlatformData,
    ),
    onDialogClose,
    onDialogConfirm: createOnDialogConfirmAction(
      actionFactoryParams,
      onDialogClose,
    ),
    onTimePickerNotificationCtaClick: createOnTimePickerNotificationCtaClick(
      goToNextAvailableDate,
    ),
    onToastClose: createOnToastCloseAction(
      actionFactoryParams,
      setCalendarErrors,
    ),
  };
};
