import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TimePickerStatus } from '../../ViewModel/timePickerViewModel/timePickerViewModel';
import {
  TriggeredByOptions,
  WidgetComponents,
  WidgetElements,
} from '../../../../utils/bi/consts';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';

export type GoToNextAvailableDate = () => void;

export function createGoToNextAvailableDateAction(
  {
    getControllerState,
    context: { biLogger, calendarApi },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
): GoToNextAvailableDate {
  return async () => {
    const [state, setState] = getControllerState();
    const { selectedDate, selectedTimezone, filterOptions } = state;

    setState({
      timePickerStatus: TimePickerStatus.LOADING,
    });

    const nextAvailableLocalDateTime = await calendarApi.getNextAvailableDate({
      from: selectedDate!,
      timezone: selectedTimezone!,
      filterOptions,
    });

    if (nextAvailableLocalDateTime) {
      await setSelectedDate(
        nextAvailableLocalDateTime,
        TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
      );

      void biLogger.bookingsCalendarClick({
        component: WidgetComponents.TIME_PICKER,
        element: WidgetElements.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        properties: JSON.stringify({
          selectedDate,
          nextAvailableDate: nextAvailableLocalDateTime,
        }),
      });
    }
  };
}
