import { SetError } from '../../../../utils/bi/consts';

import { SelectableBookingDetails } from '../../../../utils/selectableBookingDetails/selectableBookingDetails';
import { SelectedSlotOption } from '../onSlotOptionSelected/onSlotOptionSelected';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';

export const updateCalendarErrors = (
  selectableBookingDetails: SelectableBookingDetails[],
  setCalendarErrorsAction: SetCalendarErrors,
  selectedOptions?: SelectedSlotOption[],
) => {
  selectableBookingDetails.forEach((selectableBookingDetailsOption) => {
    const isMultipileOptions =
      selectableBookingDetailsOption.options.length > 1;

    const isOptionSelected = selectedOptions?.some(
      (selectedOption) =>
        selectedOption.key === selectableBookingDetailsOption.key,
    );

    if (isMultipileOptions && !isOptionSelected) {
      setCalendarErrorsAction(
        selectableBookingDetailsOption.error.key,
        SetError.ADD,
      );
    }
  });
};
