import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';

export type OnDialogClose = () => void;

export function createOnDialogCloseAction({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>): OnDialogClose {
  return () => {
    const [, setState] = getControllerState();

    setState({
      dialog: undefined,
    });
  };
}
