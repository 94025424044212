import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  TriggeredByOptions,
  WidgetComponents,
  WidgetElements,
} from '../../../../utils/bi/consts';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';

export type OnDateSelected = (
  localDateTime: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createOnDateSelectedAction(
  {
    getControllerState,
    context: { biLogger },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
): OnDateSelected {
  return async (localDateTime: string, triggeredBy: TriggeredByOptions) => {
    await setSelectedDate(localDateTime, triggeredBy);
    biLogger.bookingsCalendarClick({
      component: WidgetComponents.DATE_PICKER,
      element: WidgetElements.DATE_IN_MONTH,
    });
  };
}
