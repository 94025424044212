import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import {
  Selectables,
  SetError,
  TriggeredByOptions,
} from '../../../../utils/bi/consts';
import {
  getSelectables,
  SelectableBookingDetails,
} from '../../../../utils/selectableBookingDetails/selectableBookingDetails';

export type SelectedSlotOption = {
  key: string;
  value: string;
};
export type OnSlotOptionSelected = (
  selectedSlotOption: SelectedSlotOption,
) => Promise<void>;

export function createOnSlotOptionSelectedAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setCalendarErrorsAction: SetCalendarErrors,
): OnSlotOptionSelected {
  return async (selectedSlotOption: SelectedSlotOption) => {
    const [state, setState] = getControllerState();
    const { biLogger, settings, t, businessInfo } = context;
    const {
      selectedOptions: prevSelectedOptions,
      selectedTime,
      selectableSlots,
      calendarErrors,
    } = state;

    if (selectableSlots) {
      const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
      const selectableBookingDetails = getSelectables(
        selectableSlots,
        calendarErrors,
        t,
        settings,
        dateRegionalSettingsLocale,
        prevSelectedOptions,
      );

      const selectedOption = selectableBookingDetails.filter(
        (selectableBookingDetailsOption: SelectableBookingDetails) =>
          selectableBookingDetailsOption.key === selectedSlotOption.key,
      );
      const selectedOptionError =
        selectedOption &&
        selectedOption.length > 0 &&
        selectedOption[0].error.key;

      if (selectedOptionError) {
        setCalendarErrorsAction(selectedOptionError, SetError.REMOVE);
      }

      const selectedOptions = [...prevSelectedOptions, selectedSlotOption];

      setState({
        selectedOptions,
      });

      void biLogger.bookingsCalendarBookingDetailsLoad({
        triggeredBy: getSelectableOptionTriggeredBy(selectedSlotOption.key),
        selectedSlot: selectedTime,
        properties: JSON.stringify(selectedSlotOption),
      });
    }
  };
}

const getSelectableOptionTriggeredBy = (selectableOption: string) => {
  switch (selectableOption) {
    case Selectables.STAFF_MEMBER:
      return TriggeredByOptions.BOOKING_DETAILS_STAFF_MEMBER_SELECTABLE;
    case Selectables.LOCATION:
      return TriggeredByOptions.BOOKING_DETAILS_LOCATION_SELECTABLE;
    case Selectables.DURATION:
      return TriggeredByOptions.BOOKING_DETAILS_DURATION_SELECTABLE;
  }
};
