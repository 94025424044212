import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { OnDialogClose } from '../onDialogClose/onDialogClose';

export type OnDialogConfirm = () => void;

export function createOnDialogConfirmAction(
  { getControllerState }: ActionFactoryParams<CalendarState, CalendarContext>,
  onDialogClose: OnDialogClose,
): OnDialogConfirm {
  return () => {
    onDialogClose();
  };
}
