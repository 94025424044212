import {
  QueryAvailabilityResponse,
  SlotAvailability,
  SlotResource,
} from '@wix/ambassador-availability-calendar/types';
import { ServiceLocationType } from '@wix/bookings-uou-types';
import { getLocalDateTimeStartOfDay } from '../../../src/utils/dateAndTime/dateAndTime';

export function mockGetSlotsForSelectedDate(
  date: string,
): Promise<QueryAvailabilityResponse> {
  let availabilityEntries: SlotAvailability[] = [];
  const day = date.substr(0, date.indexOf('T'));

  if (date === mockDateAvailability.dateWithSlots1) {
    const startTimes = ['08:00', '08:00', '08:30'];
    const slotAvailability: SlotAvailability = {
      isBookable: true,
      spotsOpen: 5,
      spotsTotal: 10,
    };
    const bookableSlotsAvailability = startTimes.map(
      (startTime, index): SlotAvailability => {
        return {
          ...slotAvailability,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['12:00', '14:00'][index % 2],
            location: {
              type: ServiceLocationType.OWNER_BUSINESS,
              formattedAddress: '',
              id: `location-${index}`,
              name: ['Tel Aviv', 'Los Angeles'][index % 2],
            },
            resource: {
              id: `staffMember-${index}`,
              name: ['David', 'Jhon'][index % 2],
            },
          }),
        };
      },
    );

    const startTimesCustomOwnerLocations = ['11:00', '11:00'];
    const slotAvailabilityWithOwnerCustomLocations = startTimesCustomOwnerLocations.map(
      (startTime, index): SlotAvailability => {
        return {
          ...slotAvailability,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['12:00', '14:00'][index % 2],
            location: {
              type: ServiceLocationType.OWNER_CUSTOM,
              formattedAddress: ['At the park', 'At the Beach'][index % 2],
              id: ``,
              name: ``,
            },
            resource: {
              id: `staffMember-${index}`,
              name: ['David', 'Jhon'][index % 2],
            },
          }),
        };
      },
    );
    const startTimesClientPlaceLocations = ['13:00', '13:00'];
    const slotAvailabilityWithClientPlaceLocation = startTimesClientPlaceLocations.map(
      (startTime, index): SlotAvailability => {
        return {
          ...slotAvailability,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['14:00', '14:30'][index % 2],
            location: {
              type: ServiceLocationType.CLIENT_PLACE,
              formattedAddress: ``,
              id: ``,
              name: ``,
            },
            resource: {
              id: `staffMember-${index}`,
              name: ['David', 'Jhon'][index % 2],
            },
          }),
        };
      },
    );

    const startTimesOwnerCustomAndBusinessLocations = ['15:00', '15:00'];
    const slotAvailabilityWithOwnerBusinessWithAndWithoutIdLocations = startTimesOwnerCustomAndBusinessLocations.map(
      (startTime, index): SlotAvailability => {
        return {
          ...slotAvailability,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: ['16:00', '16:30'][index % 2],
            location: {
              type: [
                ServiceLocationType.OWNER_BUSINESS,
                ServiceLocationType.OWNER_BUSINESS,
              ][index % 2],
              formattedAddress: ``,
              id: ['123', ''][index % 2],
              name: ['Tel Aviv', 'At the park'][index % 2],
            },
            resource: {
              id: `staffMember-${index}`,
              name: ['David', 'Jhon'][index % 2],
            },
          }),
        };
      },
    );

    const fullSlotAvailability: SlotAvailability = {
      ...slotAvailability,
      slot: createSlot({
        id: 'full',
        scheduleId: `123`,
        date: day,
        startTime: '17:00',
        endTime: '18:49',
        location: {
          formattedAddress: 'Los Angeles',
          id: '456',
          name: 'Los Angeles',
        },
        resource: {
          id: '123',
          name: 'David',
        },
      }),
      spotsOpen: 10,
    };
    availabilityEntries = [
      ...bookableSlotsAvailability,
      ...slotAvailabilityWithOwnerCustomLocations,
      ...slotAvailabilityWithClientPlaceLocation,
      ...slotAvailabilityWithOwnerBusinessWithAndWithoutIdLocations,
      fullSlotAvailability,
    ];
  } else if (date === mockDateAvailability.dateWithSlots2) {
    const tooLateToBookSlotAvailability: SlotAvailability = {
      isBookable: true,
      slot: createSlot({
        id: 'tooLateToBook',
        scheduleId: `123`,
        date: day,
        startTime: '14:00',
        endTime: '15:30',
        location: {
          formattedAddress: 'Los Angeles',
          id: '1',
          name: 'Los Angeles',
        },
        resource: {
          id: '1',
          name: 'David',
        },
      }),
      spotsOpen: 4,
      spotsTotal: 10,
      bookingPolicyViolations: {
        isTooLateToBook: true,
      },
    };
    availabilityEntries = [tooLateToBookSlotAvailability];
  } else if (date === mockDateAvailability.dateWithSlots3) {
    const startTimes = ['09:00', '11:30'];
    const bookableSlotsAvailability = startTimes.map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `123`,
            date: day,
            startTime,
            endTime: '18:49',
            location: {
              formattedAddress: 'Los Angeles',
              id: '1',
              name: 'Los Angeles',
            },
            resource: {
              id: '2',
              name: 'David',
            },
          }),
          spotsOpen: 5,
          spotsTotal: 10,
        };
      },
    );
    const tooEarlyToBookSlotAvailability: SlotAvailability = {
      isBookable: true,
      slot: createSlot({
        id: 'tooEarlyToBookSlot',
        scheduleId: `123`,
        date: day,
        startTime: '18:00',
        endTime: '18:49',
        location: {
          formattedAddress: 'Los Angeles',
          id: '1',
          name: 'Los Angeles',
        },
        resource: {
          id: '1',
          name: 'David',
        },
      }),
      spotsOpen: 4,
      spotsTotal: 10,
      bookingPolicyViolations: {
        isTooEarlyToBook: true,
      },
    };
    availabilityEntries = [
      ...bookableSlotsAvailability,
      tooEarlyToBookSlotAvailability,
    ];
  } else if (date === mockDateAvailability.dateWithSlots4) {
    const fullSlotAvailability: SlotAvailability = {
      isBookable: true,
      spotsOpen: 10,
      spotsTotal: 10,
      slot: createSlot({
        id: 'full',
        scheduleId: `123`,
        date: day,
        startTime: '17:00',
        endTime: '18:49',
        location: {
          formattedAddress: 'Los Angeles',
          id: '456',
          name: 'Los Angeles',
        },
        resource: {
          id: '123',
          name: 'David',
        },
      }),
    };
    availabilityEntries = [fullSlotAvailability];
  } else if (date === mockDateAvailability.dateWithRealSession) {
    // Date for real session is 2021-04-07
    const startTimes = ['12:00'];
    const bookableSlotsAvailability = startTimes.map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `${index}`,
            scheduleId: `7c53b7d0-7850-429f-be7f-a60dbe3f395f`,
            date: day,
            startTime,
            endTime: '13:00',
            location: {
              formattedAddress: 'Los Angeles',
              id: ``,
              name: 'Los Angeles',
            },
            resource: {
              id: `76570209-101f-409b-af97-b445bdb63125`,
              name: 'David',
            },
          }),
          spotsOpen: 5,
          spotsTotal: 10,
        };
      },
    );
    availabilityEntries = [...bookableSlotsAvailability];
  }

  const queryAvailabilityResponse: QueryAvailabilityResponse = {
    availabilityEntries,
  };

  const promise = new Promise<QueryAvailabilityResponse>((resolve) => {
    setTimeout(() => resolve(queryAvailabilityResponse), 500);
  });

  return promise;
}

export const mockDateAvailability = {
  noSlotsDate: getLocalDateTimeStartOfDay(new Date()),
  dateWithSlots1: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 2),
  ),
  dateWithSlots2: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 4),
  ),
  dateWithSlots3: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 7),
  ),
  dateWithSlots4: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 10),
  ),
  dateWithNoSlots1: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 3),
  ),
  dateWithNoSlots2: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 6),
  ),
  dateWithNoSlots3: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 9),
  ),
  dateWithNoSlots4: getLocalDateTimeStartOfDay(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 12),
  ),
  dateWithRealSession: getLocalDateTimeStartOfDay(new Date(1617786000000)),
};

const createSlot = ({
  id,
  scheduleId,
  date,
  startTime,
  endTime,
  location,
  resource,
}: {
  id: string;
  scheduleId: string;
  date: string;
  startTime: string;
  endTime: string;
  location: any;
  resource: SlotResource;
}) => {
  return {
    id,
    scheduleId,
    start: `${date}T${startTime}:00Z`,
    end: `${date}T${endTime}:00Z`,
    location,
    resource,
  };
};
