import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TriggeredByOptions } from '../../../../utils/bi/consts';

export type SetSelectedMonth = (
  localDateTime: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedMonthAction({
  getControllerState,
  context: { calendarApi, biLogger },
}: ActionFactoryParams<CalendarState, CalendarContext>): SetSelectedMonth {
  return async (localDateTime: string, triggeredBy: TriggeredByOptions) => {
    const [state, setState] = getControllerState();
    const { selectedTimezone, filterOptions } = state;
    const selectedMonth = localDateTime;
    setState({
      selectedMonth,
    });

    const dateAvailability = await calendarApi.getDateAvailability({
      from: selectedMonth,
      timezone: selectedTimezone!,
      filterOptions,
    });

    setState({
      dateAvailability,
    });

    biLogger.bookingsCalendarDatePickerLoad({
      dateAvailability: JSON.stringify({
        datesWithAvailableSlots:
          dateAvailability?.availabilityEntries?.filter(
            (availabilityEntry) => availabilityEntry.hasBookableSlots,
          ).length || 0,
        datesWithUnavailableSlots:
          dateAvailability?.availabilityEntries?.filter(
            (availabilityEntry) => !availabilityEntry.hasBookableSlots,
          ).length || 0,
      }),
      triggeredBy,
    });
  };
}
