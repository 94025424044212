import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { RefetchPlatformData } from '../refetchPlatformData/refetchPlatformData';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import { FilterTypes } from '../../ViewModel/filterViewModel/filterViewModel';

export type OnFilterChanged = (
  filterTypes: FilterTypes,
  selectedValues: string[],
) => Promise<void>;

export function createOnFilterChanged(
  { getControllerState }: ActionFactoryParams<CalendarState, CalendarContext>,
  refetchPlatformData: RefetchPlatformData,
): OnFilterChanged {
  return (filterTypes: FilterTypes, selectedValues: string[]) => {
    const [state, setState] = getControllerState();

    setState({
      filterOptions: {
        ...state.filterOptions,
        [filterTypes]: selectedValues,
      },
    });

    return refetchPlatformData(TriggeredByOptions.FILTER_CHANGED);
  };
}
